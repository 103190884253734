<template>
  <el-select
    style="width: 250px"
    size="small"
    v-model="roleId"
    :loading="isLoading"
    filterable
    remote
    :remote-method="getList"
    placeholder="请选择"
    :multiple="multiple"
  >
    <el-option v-for="item in list" :label="item.role_name" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getRoleList } from './api.js'
export default {
  name: 'SelectRole',

  props: {
    value: {
      type: [String, Number, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    roleId: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        pageSize: 20,
        name: query
      }
      let res = await getRoleList(params)
      this.list = res.data
    }
  }
}
</script>

<style></style>
